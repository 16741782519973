@import '../variables.scss';

mat-button-toggle-group {
  border-radius: 0rem !important;
  border: 0 !important;

  .mat-button-toggle {
    background-color: $ta-table-header-background;
    color: $ta-black;
    padding: 0px 1rem;

    &.mat-button-toggle-checked {
      background-color: $ta-information-dark;
      color: $ta-white;
    }

    .mat-button-toggle-label-content {
      line-height: 2.5rem !important;
    }
  }
}
