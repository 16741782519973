/* open-sans-300 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/OpenSans/OpenSans-300.ttf");
}

/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/OpenSans/OpenSans-Regular.ttf");
}

/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/OpenSans/OpenSans-600.ttf");
}

/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/OpenSans/OpenSans-700.ttf");
}

* {
  font-family: Inter, Roboto, "Open Sans", Arial, Helvetica, sans-serif;
}

/* shared class with other legacy solutions, use carefully */
.menu-label {
  font-family: Inter, Roboto, "Open Sans", Arial, Helvetica, sans-serif !important;
}