mat-card {
    &.error {
        color: $ta-danger-dark;
        background-color: $ta-danger-light;
    }

    &.warning {
        color: $ta-warning-dark;
        background-color: $ta-warning-light;
    }

    &.success {
        color: $ta-success-dark;
        background-color: $ta-success-light;
    }

    &.information {
        color: $ta-information-dark;
        background-color: $ta-information-light;
    }
}