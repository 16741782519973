//Colors
$ta-steel-blue: #3276B2;
$ta-orange: #E75112;
$ta-primary-color: #FF6633;
$ta-black: #000000;
$ta-white: #FFFFFF;
$ta-light-grey: #C3C2C2;
$ta-medium-grey: #717070;
$ta-border-color: #D8D8D8;
$ta-general-background: #EEEFEE;
$ta-table-background: #E4EAEB;
$ta-link-color: #007AD9;
$ta-information-dark: #3170AA;
$ta-information-medium: #6F99C1;
$ta-information-light: #D9EDF7;
$ta-success-dark: #327E3E;
$ta-success-medium: #88BC54;
$ta-success-light: #E9EEE2;
$ta-danger-dark: #AE2C20;
$ta-danger-medium: #DE3223;
$ta-danger-light: #FDE8E7;
$ta-warning-dark: #EF791B;
$ta-warning-medium: #F59D24;
$ta-warning-light: #FFE8C7;
$ta-highlight:rgb(10, 140, 255, 0.05);

$ta-table-header-background: #F4F4F4;
$ta-table-group-header-background: $ta-highlight;
$ta-table-group-header-border: #EAEAEA;
$ta-table-nth-child-background: #F8F8F8;