@import '../variables.scss';

.navbar-header {
    float: left;
  }

  .navbar-text {
    float: left;
    margin-right: 15px;
    margin-left: 15px;
  }

  .navbar-right {
    float: right !important;
  }

  // Header
  tec-header-info-auth header {
    .header-user {
      a:hover {
        background: none;
        color: $ta-primary-color;
        text-decoration: underline;
      }
    }
    .header-logout,
    .header-help {
      &>a {
        padding: 4px 5px 3px 6px;
        margin: 10px 0 4px 7px;
        background-color: #f8f8f8;
        min-height: 0;
        border: 1px solid #bfbfbf;
        border-radius: 2px;
        color: #6D6A69;
        &:hover {
          color: #222;
          background-color: #f8f8f8;
          cursor: default;
          box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.08);
        }
      }
    }
    .header-help a {
      padding: 4px 9px 3px 8px;
    }

    .tec-header-help {
      margin-left: 4px;
      margin-top: -2px;
      background-color: #f8f8f8;
      min-height: 0;
      border: 1px solid #bfbfbf;
      border-radius: 2px;
      color: #6D6A69;
      height: 28px;
      width: 28px;
      line-height: 28px;
      font-weight: 800;
  
      &:hover {
        color: #222;
        background-color: #f8f8f8;
        cursor: default;
        box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.08);
      }
    }
    
    div.select {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 100%;
      /*overflow: hidden; Removed because hide error message */
      border: 1px solid #bdbdbd;
      position: relative;
      height: 32px;
    }
    
    div.select:before {
      content: '>';
      font: 15px "Consolas", monospace;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      border-radius: 2px;
      -webkit-box-shadow: inset 0px 0px 1px 0px #bfbfbf;
      -moz-box-shadow: inset 0px 0px 1px 0px #bfbfbf;
      box-shadow: inset 0px 0px 1px 0px #bfbfbf;
      color: #6D6A69;
      background-color: #f8f8f8;
      right: 2px;
      top: 2px;
      position: absolute;
      pointer-events: none;
      border: none;
      text-align: center;
      vertical-align: middle;
      width: 26px;
      padding: 4px 0;
      z-index: 3;
    }
    
    div.select select::-ms-expand {
      display: none;
    }
    
    div.select select {
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      width: 100%;
      padding-left: 10px;
      padding-right: 30px;
      border: 0;
      font-size: 13px;
      height: 30px;
    }
    
    div.select-sm {
      height: 29px;
    }
    
    div.select-sm select {
      height: 27px;
    }
    
    div.select-sm::before {
      top: 1px;
    }
    
    div.select select:focus {
      outline: none;
      border: 0;
    }
  }

  .top-header {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    h2 {
      margin: 0;
      padding: 0;
    }

    h4 {
      margin: 0;
      padding: 0;
    }

    @media only screen and (max-width: 800px) {
      .btn, button {
        margin-top: 1rem;
      }
    }

    .btn, button {
      margin-right: 5px;
    }
  }
  .top-header-m {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    h2 {
      margin: 0;
      padding: 0;
    }

    @media only screen and (max-width: 800px) {
      .btn, button {
        margin-top: 1rem;
      }
    }

    .btn, button {
      margin-right: 5px;
    }
  }

  .top-header-s {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;

    h2 {
      margin: 0;
      padding: 0;
    }

    @media only screen and (max-width: 800px) {
      .btn, button {
        margin-top: 1rem;
      }
    }

    .btn, button {
      margin-right: 5px;
    }
  }

  .header-logo {
    background-image: url("../../assets/images/teccom-logo.svg");
    height: 48px;
    width: 120px;

    @media screen and (max-width: 1030px) {
      background-image: url("../../assets/images/teccom-mobile-logo.svg");
      background-repeat: no-repeat;
      height: 30px;
      width: 40px;
      margin-left: 10px;
    }
  }
