mat-expansion-panel {
    .mat-expansion-panel-header {
        padding: 1rem;
        height: unset;

        &.mat-expanded {
            height: unset;
        }

        .mat-content {
            overflow: unset;
        }
    }

    .mat-expansion-panel-body {
        padding: 1rem;
        padding-top: 0;
    }
}