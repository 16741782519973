@import '../variables.scss';

mat-form-field {
    width: 100%;
    margin-bottom: .5rem;

    .mat-form-field-underline {
        background-color: $ta-light-grey;
    }
}

.mat-form-field-appearance-outline {
    .mat-form-field-prefix, .mat-form-field-suffix {
        top: -.25rem !important;
    }
}
