@use '@angular/material' as mat;
@import '@angular/material/theming';
@include mat.core();

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("~assets/fonts/OpenSans/open-sans-latin-400.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url("~assets/fonts/Roboto/roboto-latin-300.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url("~assets/fonts/Roboto/roboto-latin-400.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url("~assets/fonts/Roboto/roboto-latin-500.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


// import custom themes
@import "validation-tool/validation-tool-theme";

// // Light Theme Text
// $dark-text: #000000;
// $dark-primary-text: rgba($dark-text, 0.87);
// $dark-accent-text: rgba($dark-primary-text, 0.54);
// $dark-disabled-text: rgba($dark-primary-text, 0.38);
// $dark-dividers: rgba($dark-primary-text, 0.12);
// $dark-focused: rgba($dark-primary-text, 0.12);

// $mat-light-theme-foreground: (
//   base:              black,
//   divider:           $dark-dividers,
//   dividers:          $dark-dividers,
//   disabled:          $dark-disabled-text,
//   disabled-button:   rgba($dark-text, 0.26),
//   disabled-text:     $dark-disabled-text,
//   elevation:         black,
//   secondary-text:    $dark-accent-text,
//   hint-text:         $dark-disabled-text,
//   accent-text:       $dark-accent-text,
//   icon:              $dark-accent-text,
//   icons:             $dark-accent-text,
//   text:              $dark-primary-text,
//   slider-min:        $dark-primary-text,
//   slider-off:        rgba($dark-text, 0.26),
//   slider-off-active: $dark-disabled-text,
// );

// // Dark Theme text
// $light-text: #ffffff;
// $light-primary-text: $light-text;
// $light-accent-text: rgba($light-primary-text, 0.7);
// $light-disabled-text: rgba($light-primary-text, 0.5);
// $light-dividers: rgba($light-primary-text, 0.12);
// $light-focused: rgba($light-primary-text, 0.12);

// $mat-dark-theme-foreground: (
//   base:              $light-text,
//   divider:           $light-dividers,
//   dividers:          $light-dividers,
//   disabled:          $light-disabled-text,
//   disabled-button:   rgba($light-text, 0.3),
//   disabled-text:     $light-disabled-text,
//   elevation:         black,
//   hint-text:         $light-disabled-text,
//   secondary-text:    $light-accent-text,
//   accent-text:       $light-accent-text,
//   icon:              $light-text,
//   icons:             $light-text,
//   text:              $light-text,
//   slider-min:        $light-text,
//   slider-off:        rgba($light-text, 0.3),
//   slider-off-active: rgba($light-text, 0.3),
// );

// // Background config
// // Light bg
// $light-background:    #fafafa;
// $light-bg-darker-5:   darken($light-background, 5%);
// $light-bg-darker-10:  darken($light-background, 10%);
// $light-bg-darker-20:  darken($light-background, 20%);
// $light-bg-darker-30:  darken($light-background, 30%);
// $light-bg-lighter-5:  lighten($light-background, 5%);
// $dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
// $dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

// $mat-light-theme-background: (
//   background:               $light-background,
//   status-bar:               $light-bg-darker-20,
//   app-bar:                  $light-bg-darker-5,
//   hover:                    $dark-bg-alpha-4,
//   card:                     $light-bg-lighter-5,
//   dialog:                   $light-bg-lighter-5,
//   disabled-button:          $dark-bg-alpha-12,
//   raised-button:            $light-bg-lighter-5,
//   focused-button:           $dark-focused,
//   selected-button:          $light-bg-darker-20,
//   selected-disabled-button: $light-bg-darker-30,
//   disabled-button-toggle:   $light-bg-darker-10,
//   unselected-chip:          $light-bg-darker-10,
//   disabled-list-option:     $light-bg-darker-10,
//   tooltip: map_get($mat-grey, 700),
// );

// // Dark bg
// $dark-background:     #2c2c2c;
// $dark-bg-lighter-5:   lighten($dark-background, 5%);
// $dark-bg-lighter-10:  lighten($dark-background, 10%);
// $dark-bg-lighter-20:  lighten($dark-background, 20%);
// $dark-bg-lighter-30:  lighten($dark-background, 30%);
// $light-bg-alpha-4:    rgba(#fafafa, 0.04);
// $light-bg-alpha-12:   rgba(#fafafa, 0.12);

// // Background palette for dark themes.
// $mat-dark-theme-background: (
//   background:               $dark-background,
//   status-bar:               $dark-bg-lighter-20,
//   app-bar:                  $dark-bg-lighter-5,
//   hover:                    $light-bg-alpha-4,
//   card:                     $dark-bg-lighter-5,
//   dialog:                   $dark-bg-lighter-5,
//   disabled-button:          $light-bg-alpha-12,
//   raised-button:            $dark-bg-lighter-5,
//   focused-button:           $light-focused,
//   selected-button:          $dark-bg-lighter-20,
//   selected-disabled-button: $dark-bg-lighter-30,
//   disabled-button-toggle:   $dark-bg-lighter-10,
//   unselected-chip:          $dark-bg-lighter-20,
//   disabled-list-option:     $dark-bg-lighter-10,
//   tooltip: map_get($mat-grey, 700),
// );

// // Compute font config
// @include mat-core($fontConfig);

// // Theme Config

// body {
//   --primary-color: #3276b1;
//   --primary-lighter-color: #c2d6e8;
//   --primary-darker-color: #1f5998;
//   --text-primary-color: #{$light-primary-text};
//   --text-primary-lighter-color: #{$dark-primary-text};
//   --text-primary-darker-color: #{$light-primary-text};
// }

// $mat-primary: (
//   main: #3276b1,
//   lighter: #c2d6e8,
//   darker: #1f5998,
//   200: #3276b1, // For slide toggle,
//   contrast : (
//     main: $light-primary-text,
//     lighter: $dark-primary-text,
//     darker: $light-primary-text,
//   )
// );
// $theme-primary: mat-palette($mat-primary, main, lighter, darker);

// body {
//   --accent-color: #ff6633;
//   --accent-lighter-color: #ffd1c2;
//   --accent-darker-color: #ff4920;
//   --text-accent-color: #{$dark-primary-text};
//   --text-accent-lighter-color: #{$dark-primary-text};
//   --text-accent-darker-color: #{$light-primary-text};
// }

// $mat-accent: (
//   main: #ff6633,
//   lighter: #ffd1c2,
//   darker: #ff4920,
//   200: #ff6633, // For slide toggle,
//   contrast : (
//     main: $dark-primary-text,
//     lighter: $dark-primary-text,
//     darker: $light-primary-text,
//   )
// );
// $theme-accent: mat-palette($mat-accent, main, lighter, darker);

// body {
//   --warn-color: #ff0000;
//   --warn-lighter-color: #ffb3b3;
//   --warn-darker-color: #ff0000;
//   --text-warn-color: $light-primary-text;
//   --text-warn-lighter-color: $dark-primary-text;
//   --text-warn-darker-color: $light-primary-text;
// }

// $mat-warn: (
//   main: #ff0000,
//   lighter: #ffb3b3,
//   darker: #ff0000,
//   200: #ff0000, // For slide toggle,
//   contrast : (
//     main: $light-primary-text,
//     lighter: $dark-primary-text,
//     darker: $light-primary-text,
//   )
// );
// $theme-warn: mat-palette($mat-warn, main, lighter, darker);;

// $theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
// $altTheme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);
// // Theme Init
// @include angular-material-theme($theme);

// .validation-tool-theme {
//   @include validation-tool-theme($theme);
// }

// .theme-alternate {
//   @include angular-material-theme($altTheme);
// }


// // Specific component overrides, pieces that are not in line with the general theming

// // Handle buttons appropriately, with respect to line-height
// .mat-raised-button, .mat-stroked-button, .mat-flat-button {
//   padding: 0 1.15em;
//   margin: 0 .65em;
//   min-width: 3em;
//   line-height: 36.4px
// }

// .mat-standard-chip {
//   padding: .5em .85em;
//   min-height: 2.5em;
// }


// // Override TecWeb label color
// .mat-checkbox-label {
//   color: rgba(0, 0, 0, 0.87);
// }

// mat-radio-button label.mat-radio-label .mat-radio-label-content {
//   color: rgba(0, 0, 0, 0.87);
// }

// button.mat-raised-button {
//   line-height: 32px;
//   padding: 0 12px;
//   text-transform: unset !important;

//   &.mat-primary, &.mat-accent {
//     color: white;
//   }
// }

// .mat-icon {
//   &.fa {
//     font-size: 23px;
//   }
// }

// .mat-form-field {
//   letter-spacing: normal !important;

//   input[readonly] {
//     background: inherit !important;
//   }
// }

// .small-card {
//   .mat-card-header-text {
//     overflow: hidden;

//     mat-card-title {
//       text-overflow: ellipsis;
//       white-space: nowrap;
//       overflow: hidden;
//     }
//   }

//   .mat-expansion-panel-body {
//     display: flex;
//     flex-wrap: wrap;
//   }
// }

// .mat-tooltip {
//   white-space: pre-line;
// }