mat-progress-bar.mat-progress-bar {
    height: .5rem;
    overflow: unset;
    display: flex;

    svg.mat-progress-bar-background {
        width: 100% !important;
    }

    &.error .mat-progress-bar-fill::after {
        background-color: $ta-danger-medium;
    }

    &.warning .mat-progress-bar-fill::after {
        background-color: $ta-warning-medium;
    }

    &.success .mat-progress-bar-fill::after {
        background-color: $ta-success-medium;
    }

    &.information .mat-progress-bar-fill::after {
        background-color: $ta-information-medium;
    }

    .mat-progress-bar-buffer {
        background-color: $ta-general-background;
    }

    &.validation-process .mat-progress-bar-fill::after {
        background-color: $ta-success-medium;
    }
    
    &.validation-process
    .mat-progress-bar-buffer {
        background-color: $ta-danger-medium;
    }
}