mat-radio-button {
    .mat-radio-container,
    .mat-radio-outer-circle,
    .mat-radio-inner-circle {
        height: 1rem;
        width: 1rem;
    }
  
    .mat-radio-label-content {
        padding-left: .75rem;
    }
    ~ mat-radio-button {
        margin-left: 2rem;
    }
}