@import 'variables.scss';

.container {
    padding: 1rem;
}

.highlight {
    background-color: $ta-highlight;
    border-color: $ta-highlight;
}

.column-2 {
    column-gap: 5rem;
    column-count: 2;
    -moz-column-count: 2;
    -webkit-column-count: 2;
}

.column-3 {
    column-gap: 5rem;
    column-count: 3;
    -moz-column-count: 3;
    -webkit-column-count: 3;
}

.column-4 {
    column-gap: 2.5rem;
    column-count: 4;
    -moz-column-count: 4;
    -webkit-column-count: 4;
}

.column-5 {
    column-gap: 1.5rem;
    column-count: 5;
    -moz-column-count: 5;
    -webkit-column-count: 5;
}

.column-item {
    break-inside: avoid-column;
    /* for Firefox */
    page-break-inside: avoid;
}

body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
  background-color: $ta-general-background;
}

.hidden {
    display: none !important;
}

.truncate-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.tp-icon,
i {
    height: 1rem;
    width: 1rem;

    &.tp-white-icon {
        color: $ta-white;
    }

    &.tp-primary-icon {
        color: $ta-steel-blue;
    }

    &.tp-warning-icon {
        color: $ta-warning-medium;
    }

    &.tp-error-icon {
        color: $ta-danger-medium;
    }
}

.tp-unpublish-icon {
    background-position: 5px 2px;
    background-image: url('~assets/images/unpublish.svg');
}

.tec-page-title {
    width: auto;
    display: inline-block;
    font-size: 30px;
    font-family: inherit;
    font-weight: 500;
    position: relative;
    margin: 0;
    letter-spacing: 0;
  }

:focus-visible { outline: none !important; }

.highlighted-text {
    background: yellow;
}

a {
    color: #337ab7;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.column-width-5 {
    width: 5%
}

.column-width-10 {
    width: 10%
}

.column-width-15 {
    width: 15%
}

.column-width-20 {
    width: 20%
}

.column-width-25 {
    width: 25%
}

.column-width-30 {
    width: 30%
}

.column-width-40 {
    width:  40%
}

.column-width-45 {
    width: 45%
}
