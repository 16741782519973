@use '@angular/material' as mat;
@import '@angular/material/theming';
@import '../variables.scss';

$ta-typography: mat.define-typography-config($font-family: 'Open Sans',
  $display-4: mat.define-typography-level(112px, 112px, 300, 'Open Sans', 0em),
  $display-3: mat.define-typography-level(56px, 56px, 600, 'Open Sans', 0em),
  $display-2: mat.define-typography-level(45px, 48px, 400, 'Open Sans', 0em),
  $display-1: mat.define-typography-level(34px, 40px, 600, 'Open Sans', 0em),
  $headline: mat.define-typography-level(18px, 24px, 700, 'Open Sans', 0em),
  $title: mat.define-typography-level(14px, 24px, 600, 'Open Sans', 0em),
  $subheading-2: mat.define-typography-level(14px, 22px, 600, 'Open Sans', 0em),
  $subheading-1: mat.define-typography-level(14px, 20px, 400, 'Open Sans', 0em),
  $body-2: mat.define-typography-level(14px, 20px, 400, 'Open Sans', 0em),
  $body-1: mat.define-typography-level(14px, 20px, 400, 'Open Sans', 0em),
  $button: mat.define-typography-level(14px, 32px, 500, 'Open Sans', 0em),
  $caption: mat.define-typography-level(12px, 16px, 400, 'Open Sans', 0em),
  $input: mat.define-typography-level(inherit, 1.25, 400, 'Open Sans', 0em));

// Override the typography in the core CSS.
@include mat.core($ta-typography);

.tp-headline,
.mat-headline,
.mat-h1,
h1 {
  @include mat.typography-level($ta-typography, headline);
  margin-bottom: 1rem;
}

.tp-title,
.mat-title,
.mat-h2,
h2 {
  @include mat.typography-level($ta-typography, title);
}

.tp-subheading-2,
.mat-subheading-2,
.mat-h3,
h3 {
  @include mat.typography-level($ta-typography, subheading-2);
}

.tp-subheading,
.mat-subheading-1,
.mat-h4,
h4 {
  @include mat.typography-level($ta-typography, subheading-1);
}

// Note: the spec doesn't have anything that would correspond to h5 and h6, but we add these for
// consistency. The font sizes come from the Chrome user agent styles which have h5 at 0.83em
// and h6 at 0.67em.
.tp-h5,
h5 {
  @include mat-typography-font-shorthand( // calc is used here to support css variables
    calc(#{mat.font-size($ta-typography, body-1)} * 0.83),
    mat.font-weight($ta-typography, body-1),
    mat.line-height($ta-typography, body-1),
    mat.font-family($ta-typography, body-1));
  margin: 0;
}

.tp-h6,
h6 {
  @include mat-typography-font-shorthand( // calc is used here to support css variables
    calc(#{mat.font-size($ta-typography, body-1)} * 0.67),
    mat.font-weight($ta-typography, body-1),
    mat.line-height($ta-typography, body-1),
    mat.font-family($ta-typography, body-1));
  margin: 0;
}

.tp-body-strong,
.tp-body-2,
.mat-body-strong,
.mat-body-2 {
  @include mat.typography-level($ta-typography, body-2);
}

.tp-body,
.tp-body-1,
.mat-body,
.mat-body-1,
body {
  @include mat.typography-level($ta-typography, body-1);
}

.tp-small,
.tp-caption,
.mat-small,
.mat-caption {
  @include mat.typography-level($ta-typography, caption);
  margin: 0;
}

.tp-display-4,
.mat-display-4 {
  @include mat.typography-level($ta-typography, display-4);
  margin: 0;
}

.tp-display-3,
.mat-display-3 {
  @include mat.typography-level($ta-typography, display-3);
  margin: 0;
}

.tp-display-2,
.mat-display-2 {
  @include mat.typography-level($ta-typography, display-2);
  margin: 0;
}

.tp-display-1,
.mat-display-1 {
  @include mat.typography-level($ta-typography, display-1);
  margin: 0;
}

.tp-text-break {
  overflow-wrap: break-word;
  word-break: break-word;
  /* Chrome, Safari */
  word-wrap: break-word;
  /* IE11, Firefox */
}

.tp-bold,
.bold {
  font-weight: 700 !important;
}

.tp-extra-bold {
  font-weight: 800 !important;
}

.tp-semibold,
.semibold {
  font-weight: 600 !important;
}

.tp-lighter,
.lighter {
  font-weight: 300 !important;
}

.tp-italic,
.italic {
  font-style: italic;
}


.tp-text-light,
.text-light {
  color: $ta-medium-grey !important;
}
