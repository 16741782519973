@import '../variables.scss';

.tec-confirmation {
 .p-dialog {
   .p-dialog-header,
   .p-dialog-content,
   .p-dialog-footer {
     border: 0;
     max-width: 800px;
     margin: 0 auto;
   }

   background: #fff;
   width: 100%;
   display: block;

   .p-dialog-header {
     background: #fff;
     padding-bottom: 0;
     justify-content: flex-start;

     .p-dialog-title {
       font-size: 24px;
       font-weight: 500;
     }
   }

   .p-dialog-footer {
     padding: 0 1em 1em;
   }

   .p-dialog-content {
     padding: 1em 2em;
   }
 }

 &[key="discard-changes"],
 &[key="header-info-confirmation"],
 &[key="warning-confirmation"] {
   .p-dialog.p-confirm-dialog {
     .p-dialog-header {
       &:before {
         display: inline-block;
         font-family: FontAwesome, serif;
         font-size: 24px;
         color: $ta-primary-color;
       }
     }
   }
 }

 &[key="discard-changes"],
 &[key="header-info-confirmation"] {
   .p-dialog.p-confirm-dialog {
     .p-dialog-header {
       &:before {
         content: "\f2f5";
         font-weight: 900;
         font-family: 'Font Awesome 5 Free';
       }
     }
   }
 }

 &[key="warning-confirmation"] {
   .p-dialog.p-confirm-dialog {
     .p-dialog-header {
       &:before {
         content: "\f071";
         font-weight: 900;
         font-family: 'Font Awesome 5 Free';
       }
     }
   }
 }
}