button {
    &.button-icon {
        min-width: 1.5rem;
        padding: 0px .5rem;
        line-height: 32px;
    }

    &.mat-icon-button i,
    &.mat-icon-button .mat-icon {
        line-height: initial;
    }

    &.success {
        background-color: $ta-success-medium;
    }
}

button.mat-raised-button {
    border: solid $ta-steel-blue 1px;
    color: $ta-steel-blue;
}

button.mat-raised-button:disabled {
    border: none;
}

button.mat-focus-indicator.mr-3.icon-button-small {
    width: 48px;
    min-width: unset;
}