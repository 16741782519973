@import 'variables.scss';

.p-datatable {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0;

    .p-datatable-thead {
        tr:first-child {
            th {
                font-weight: 600;
                color: $ta-black;
                text-align: left;
                background-color: $ta-table-header-background;
                padding: 1rem 0.1rem;
                border: none;

                &.p-sortable-column,
                &.p-sortable-column:hover {
                    color: $ta-black;
                    background-color: $ta-table-header-background;
                    box-shadow: none;

                    .p-sortable-column-icon {
                        color: $ta-black;
                        opacity: 0.5;
                    }
                }

                &.column-action {
                    width: 2rem;
                }

                &.column-actions {
                    min-width: 7rem;
                }

                &.column-width-20 {
                    width: 20%
                }

                &.column-width-25 {
                    width: 25%
                }
            }
        }
    }

    .p-datatable-thead {
        tr:nth-child(2) {
            th {
                font-weight: 600;
                font-size: 12px;                
                color: $ta-black;
                text-align: left;
                background-color: $ta-table-header-background;
                padding: 0.3rem 0.1rem;
                border-top: 1px solid #dee2e6;
            }
        }

        .p-dropdown .p-dropdown-clear-icon {
            padding-top: 2px;
        }
        
        .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
            padding-top: 2px;
        }   
    }

    .p-inputtext {
        padding: 5px;
        font-size: 12px;
    }
    
    .p-column-filter-row .p-column-filter-menu-button, .p-column-filter-row .p-column-filter-clear-button {
        margin-left: 0px;
    }
    
    .p-datatable-tbody {
        tr {
            &:nth-child(even) {
                background: $ta-table-nth-child-background;
            }

            td {
                border: none;
                padding: .55rem .5rem;
            }

            &.group-header {
                background-color: $ta-table-group-header-background !important;
                border: none;
                border-bottom: 1px solid $ta-table-group-header-border;
                font-weight: 600;

                td {
                    padding-top: 0.75rem;
                    padding-bottom: 0.75rem;
                }
            }
        }
    }

    .p-paginator {
        border: unset;
        border-radius: unset;
        padding: .5rem 0;
        background-color: $ta-table-header-background;

        .p-paginator-element {
            min-width: 1.875rem;
            height: 1.875rem;
            font-size: 14px;

            &:focus,
            &:hover {
                border: none;
                border-radius: unset;
            }
        }

        .p-paginator-current {
            height: 1rem;
            font-size: 12px;
        }

        .p-paginator-page,
        .p-dropdown {
            border: none;
            border-radius: unset;
            min-width: 1.875rem;
            height: 1.875rem;

            .p-dropdown-label {
                padding: 0;
                padding-left: .75rem;
                text-align: center;
                align-self: center;
                font-size: 14px;
            }

            .p-dropdown-trigger {
                width: 20px;
            }

            .p-dropdown-trigger-icon {
                font-family: "Font Awesome 5 Free", "primeicons";
                font-weight: 600;
                font-size: 12px;
                padding-top: 2px;
            }
        }

        .pi {
            font-size: 14px;
        }

        .p-dropdown-item {
            padding: .45rem .85rem;
            font-size: 14px;
            text-align: center;
        }

        .p-paginator-page.p-highlight,
        .p-dropdown-item.p-highlight {
            background-color: $ta-steel-blue;
            border-color: $ta-steel-blue;
            color: $ta-white;
        }
    }

    .p-datatable-thead {
        .pi {
            font-family: "Font Awesome 5 Free", "primeicons";
            font-weight: 600;
            font-size: 14px;

            &.pi-sort-alt:before {
                content: "\f0dc"
            }

            &.pi-sort-amount-down:before {
                content: "\f0dd";
            }

            &.pi-sort-amount-up-alt:before {
                content: "\f0de";
            }
        }
    }
}

.group-table .p-datatable .p-datatable-tbody>tr {
    &:nth-child(even) {
        background: $ta-white;
    }

    &:nth-child(odd) {
        background: $ta-table-nth-child-background;
    }
}

.p-column-filter-row-items {
    font-size: 12px;
}
